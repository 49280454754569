@import "../node_modules/bootstrap/dist/css/bootstrap-reboot.css";
@import "../node_modules/bootstrap/dist/css/bootstrap-grid.css";
@import url(~fullpage.js/dist/fullpage.min.css);
@import url("https://fonts.googleapis.com/css?family=Quicksand&display=swap");
@import "../node_modules/fullpage.js/dist/fullpage.css";


@mixin linear-gradient($direction, $color-stops...) {
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(
    legacy-direction($direction),
    $color-stops
  );
  background: linear-gradient($direction, $color-stops);
}

@import "variables.scss";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; height: 100%;}

h1,
h2,
h3,
h4 {
  font-family: "Quicksand", sans-serif !important;
}

h3 {
  font-size: 1.2em !important;
}

@media screen and (max-width: 575px) {
  h2 {
    font-size: 1.5em !important;
  }
}

.noPadding {
  padding: 0 !important;
}

.styledH2 {
  color: black;
  margin-bottom: 20px;
}

body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

body {
  background-color: #e1e1e1;
}

a {
  color: white;
  text-decoration: none !important;
}

.container-fluid {
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.align-top {
  .fp-tableCell {
    vertical-align: top !important;
  }
}

.fp-slidesNav {
  text-align: center;

  ul {
    li {
      span {
        background: white !important;
      }
    }
  }
}

.fp-controlArrow.fp-prev {
  left: 50px !important;
}

.fp-controlArrow.fp-next {
  right: 50px !important;
}

cover {
  height: 100%;
}

projects-widget {
  position: fixed;
  bottom: 0;
  z-index: 5;
  color: white;
  background-color: #444444;
}

.projects-section {
  .fp-tableCell {
    vertical-align: top;
    padding-top: 7vh;
  }
}

.projects-overlay {
  background-image: linear-gradient(
    rgba(68, 68, 68, 1),
    rgba(68, 68, 68, 0.3),
    rgba(68, 68, 68, 0)
  );
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

menu {
  //   position: absolute;
  margin: 0;
  //   padding: 0;
  //   height: 80px;
  //   width: 100%;
  //   z-index: 99 !important;
}

#myVideo {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  object-fit: cover;
  z-index: 3;
}

.mobileHomeImg {
  display: none;
  position: absolute;
  top: 0;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
}

//Uncomment conditional brackets for video view
// @media screen and (max-width: 1366px) {
.mobileHomeImg {
  display: block;
}

#myVideo {
  display: none;
}

// }

.section {
  background-size: cover;
  background-position: center center;
}

.section_home .layer {
  position: absolute;
  z-index: 4;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  color: white;

  /*
	* Preventing flicker on some browsers
	* See http://stackoverflow.com/a/36671466/1081396  or issue #183
	*/
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/*solves problem with overflowing video in Mac with Chrome */
.section_home {
  overflow: hidden;
}

.logospace {
  text-align: center;
  z-index: 100;
  cursor: default;

  h1 {
    padding: 10px;
  }
}

.logospace h1 {
  margin-bottom: 0;
  margin-top: 0;
  text-shadow: 1px 1px 5px #333333;
}

.logospace span {
  display: inline-block;
  font-size: 1.5em;
  padding: 4px;
  border-radius: 2px;
  margin-top:100px;
}

.logo {
  width: 20vw;
}

@media screen and (max-width: 500px) {
  .logo {
    width: 47vw;
    padding-top: 3vh;
  }
}

.downer {
  cursor: pointer;
  width: 100%;
  text-align: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 89;
  bottom: 0;
  height: 20px;

  i {
    font-size: 2em;
    color: white;
    display: inline-block;
    height: 17px;
    position: absolute;
    bottom: 10px;
  }
}

twitter {
  position: absolute;
  left: 0px;
  z-index: 100;
  top: 80px;
  height: calc(100vh - 100px) !important;
}

@media screen and (max-width: 575px) {
  .team {
    padding: 0 !important;
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.posRelative {
  .posts {
    position: relative !important;
  }
}

.goBack {
  cursor: pointer !important;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
